import React from 'react'

import Layout from '../../components/layout'
import Head from '../../components/head'

import Surveillanceposter2 from './surveillanceposter2.jpg'

const SurveillanceposterPage = () => {
    return (
        <Layout image={Surveillanceposter2} footerImageDescription='Surveillance - Poster Design'>
            <Head title="Surveillance -"/>
        </Layout>
    )
}

export default SurveillanceposterPage